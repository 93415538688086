<template>
	<page-container class="index" :page="page">
		<heading-image>
			<hero-image v-if="page.headingImage" type="fullscreen">
				<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
					<p>
						<span>{{ defaults[locale].homepage.headingImageTitle }}</span>
					</p>
					<div v-parse-links v-html="defaults[locale].homepage.headingImageContent ?? ''" />
				</div>
				<div class="slider-overlay" />
				<picture>
					<source
						v-if="page.headingImagePortraitWebp"
						:srcset="page.headingImagePortraitWebp"
						media="(max-width: 500px)"
						type="image/webp"
					/>
					<source
						v-if="page.headingImagePortrait"
						:srcset="page.headingImagePortrait"
						media="(max-width: 500px)"
					/>
					<source :srcset="page.headingImageWebp" type="image/webp" />
					<source :srcset="page.headingImage" />
					<img class="object-fit" :src="page.headingImage" :alt="page.headingImageAlt" />
				</picture>
			</hero-image>

			<booking-form :booking-form="defaults[locale].homepage" />
		</heading-image>

		<main-content>
			<div ref="intro" class="intro-wrapper">
				<div class="columns column6 intro-image">
					<picture>
						<source :srcset="defaults[locale].homepage.introImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].homepage.introImage" />
						<img
							:src="defaults[locale].homepage.introImage"
							:alt="defaults[locale].homepage.introImageAlt"
							loading="lazy"
						/>
					</picture>
				</div>
				<div class="columns column6 intro-content">
					<div class="content-wrapper">
						<div class="heading-wrapper">
							<h1>{{ page.title }}</h1>
							<h2 class="subtitle">
								<div class="title-logo" />
								<span>The Sixteen</span>
							</h2>
						</div>
						<div v-parse-links v-html="page.content" />
					</div>
				</div>
			</div>
		</main-content>

		<mosaic :items="mosaicData" />

		<contentblocks :items="page.contentblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<div class="title-logo" />
						<h2>
							{{ defaults[locale].homepage.sectionContentblocksTitle }}
						</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionContentblocksContent ?? ''" />
					</div>
				</div>
			</template>
		</contentblocks>

		<promoblocks :items="promoblocksData">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<div class="title-logo" />
						<h2>
							{{ defaults[locale].homepage.sectionPromotionTitle }}
						</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionPromotionContent ?? ''" />
					</div>
				</div>
			</template>
		</promoblocks>

		<review-slider v-if="page.showReviewSlider" :header="defaults[locale].website.reviewsHeader" />

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}

const { data: mosaicData } = await useWebsiteFetch('mosaic', {
	query: { language: locale.value },
	key: `${locale.value}/mosaic`,
});

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
.slider-overlay {
	position: absolute;
	inset: 0;
	z-index: 2;
	background: rgb(0 0 0 / 25%);
}

.main-content.intro {
	padding: 0;
}

.intro-wrapper {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: stretch;
	background: linear-gradient(
		90deg,
		#fff 0%,
		#fff 50%,
		var(--light-background-color) 50%,
		var(--light-background-color) 100%
	);

	.intro-content,
	.intro-image {
		max-width: 860px;
	}

	.intro-content {
		padding: 20px 60px;
		background: var(--light-background-color);
	}

	.intro-image {
		background: #fff;
		padding: 70px;
	}

	.content-wrapper {
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	:deep(.no-fill) {
		border-color: #878787;
		color: #878787;

		&:hover {
			border-color: var(--cta-color-hover);
		}
	}
}

.title-logo {
	background: url('~/assets/images/the-sixteen-logo-icon-dark.png') no-repeat center center;
	background-size: 50px;
	width: 50px;
	height: 80px;
	margin: 0 auto 20px;
}

.promotions {
	background: linear-gradient(
		180deg,
		var(--secondary-background-color) 0%,
		var(--secondary-background-color) 60%,
		var(--light-background-color) 60%,
		var(--light-background-color) 100%
	);
}

.subtitle {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	font-family: var(--body-font-family);
	font-weight: 600;
	text-transform: uppercase;
	color: #878787;
	font-size: 17px;
	letter-spacing: 3px;

	.title-logo {
		margin: 0 20px 0 0;
		background: url('~/assets/images/the-sixteen-logo-icon-dark.png') no-repeat center center;
		width: 29px;
		height: 46px;
		background-size: 29px;
	}
}

.hero-content {
	position: absolute;
	max-width: 900px;
	z-index: 10;
	left: 0;
	right: 0;
	width: auto;
	bottom: 40%;
	margin: 0 auto;
	text-align: center;
	color: #fff;
	letter-spacing: 2px;

	:deep(span) {
		color: #fff;
		font-size: 4.6rem;
		font-family: var(--heading-font-family);
		font-weight: 600;
		margin: 0 0 10px;
		width: 100%;
		display: inline-block;
		line-height: 1;
	}

	:deep(p) {
		font-size: 1.8em;
	}
}

.hero-scroll {
	width: 64px;
	height: 64px;
	line-height: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	border: 2px solid #fff;
	font-size: 24px;
	z-index: 10;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	bottom: 200px;
	cursor: pointer;
}

@media (max-width: 1280px) {
	.hero-content {
		:deep(span) {
			font-size: 4.2rem;
		}
	}
}

@media (max-width: 1080px) {
	.intro-wrapper {
		.intro-content,
		.intro-image {
			max-width: 100%;
		}

		.intro-image {
			padding: 40px;
		}
	}

	.hero-content {
		:deep(span) {
			font-size: 4rem;
		}
	}
}

@media (max-width: 880px) {
	.hero-content {
		bottom: 30%;

		:deep(span) {
			font-size: 2.4rem;
			line-height: 1.3;
		}

		:deep(p) {
			font-size: 1.6em;
			padding: 0 10px;
		}
	}

	.hero-scroll {
		bottom: 50px;
	}
}
</style>
